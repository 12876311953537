export function loadJS(
	FILE_URL: string,
	{
		async = true,
		callback,
	}: {
		async: boolean;
		callback: () => {};
	},
) {
	const scriptEle = document.createElement('script');

	scriptEle.setAttribute('src', FILE_URL);
	scriptEle.setAttribute('type', 'text/javascript');
	scriptEle.setAttribute('async', async.toString());

	document.body.appendChild(scriptEle);

	// success event
	scriptEle.addEventListener('load', () => {
		callback?.();

		scriptEle.remove();
	});
	// error event
	scriptEle.addEventListener('error', (ev) => {
		console.log('Error on loading file', ev);
	});
}
