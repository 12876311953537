import { useMount } from 'ahooks';
import { loadJS } from './loadJs';

export function useCopyFunc() {
	useMount(() => {
		loadJS('https://unpkg.com/clipboard@2.0.11/dist/clipboard.min.js', {
			async: true,
			callback: () => {
				// @ts-expect-error i know that
				return new ClipboardJS('.copy');
			},
		});
	});
}
